<template>
    <div class="font-app">
        <div class="mb-3 text-center">
            <img
                @click="$router.push({ name: 'Home' })"
                class="auth-logo"
                alt="logo"
                src="@/assets/logo.png"
            />
        </div>
        <div>
            <div class="mb-4">
                <text-input v-model="email" validation-rules="email|required">
                    <template v-slot:label> Email </template>
                </text-input>

                <div class="w-100 mb-4"></div>
                <password-input v-model="password">
                    <template v-slot:label> Password </template>
                </password-input>

                <a
                    class="mt-2 input-label-spacing"
                    @click="
                        $router.push({
                            name: 'SendPasswordReset',
                            query: { email: email },
                        })
                    "
                    href="javascript:;"
                >
                    Forgot Password?
                </a>
                <div class="w-100 mb-3"></div>
                <div>
                    <vue-recaptcha
                        class="g-recaptcha"
                        ref="recaptcha"
                        @verify="captchaVerified"
                        @expired="captchaExpired"
                        sitekey="6Ldv4NEZAAAAAJJ9fIJXbyk7KY3XPrMOH9biWU_r"
                    />
                </div>

                <div
                    class="text-small text-danger text-center"
                    v-if="needsVerify"
                >
                    Please verify before continuing
                </div>
            </div>
            <div class="text-center">
                <div class="mb-5">
                    <btn app block @click="redirect" :loading="signInLoading">
                        Sign In
                    </btn>
                    <div
                        class="error_message h5 p-0 pt-1 m-0 text-center w-100 text-danger"
                        v-if="errors.length"
                    >
                        <i class="fas fa-warning mr-1"></i> {{ errors[0] }}
                    </div>
                </div>
                <span
                    >Don't have an account?

                    <a
                        href="javascript:;"
                        @click="$router.push({ name: 'Signup' })"
                    >
                        Sign Up
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import passwordInput from '@/components/forms/password-input';
import VueRecaptcha from 'vue-recaptcha';

const DEBUG = false;

export default {
    data() {
        return {
            init: false,
            email: null,
            email_bool: true,
            password: null,
            password_bool: true,
            errors: [],
            disablebTag: true,
            needsVerify: false,
            valid1: false,
            valid2: false,
            animated: false,
            signInLoading: false,
            mounting: true,
        };
    },
    props: {
        source: String,
    },
    computed: {
        invalid1: function () {
            return this.valid1 ? 'block' : 'none';
        },
        invalid2: function () {
            return this.valid2 ? 'block' : 'none';
        },
    },
    watch: {
        email(newVal) {
            const emailRegEx = new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
            if (this.init === false) {
                this.valid1 = false;
                this.valid2 = false;
            }
            this.init = true;
            if (emailRegEx.test(newVal)) {
                this.email_bool = true;
                this.valid1 = false;
            } else {
                this.email_bool = false;
                this.valid1 = true;
            }
        },
        password(newVal) {
            if (this.init === false) {
                this.valid1 = false;
                this.valid2 = false;
            }
            this.init = true;
            if (newVal) {
                this.password_bool = true;
                this.valid2 = false;
            } else {
                this.password_bool = false;
                this.valid2 = true;
            }
        },
    },
    components: {
        passwordInput,
        VueRecaptcha,
    },
    mounted() {
        this.$nextTick().then(() => {
            setTimeout(() => {
                this.mounting = false;
            }, 100);
        });
    },
    methods: {
        clickHandler() {
            const self = this;
            self.animated = true;
            setTimeout(() => {
                self.animated = false;
            }, 1000);
        },
        redirect() {
            if (! DEBUG) {
                if (this.disablebTag) {
                    this.needsVerify = true;
                    return;
                }
            }
            if (
                this.init === false &&
                this.email === null &&
                this.password === null
            ) {
                this.valid1 = true;
                this.valid2 = true;
            }
            if (this.init && this.email_bool && this.password_bool) {
                this.signInLoading = true;
                this.$store
                    .dispatch('obtainToken', {
                        email: this.email,
                        password: this.password,
                    })
                    .then(() => {
                        var redirect = this.$route.query.redirect;
                        if (redirect) {
                            this.$router.push({ path: redirect });
                        } else {
                            this.$router.push({ name: 'Dashboard' });
                        }
                        this.signInLoading = false;
                    })
                    .catch((err) => {
                        this.signInLoading = false;
                        if (this.errors.length === 0) {
                            this.errors.push('Invalid email or password');
                        } else {
                            this.clickHandler();
                        }
                    });
            }
        },
        captchaVerified() {
            this.disablebTag = false;
            this.needsVerify = false;
            // this.$refs.recaptcha.execute();
        },
        captchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
    },
};
</script>
