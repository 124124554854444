<template>
    <auth-wrapper>
        <LoginBase />
    </auth-wrapper>
</template>

<script>
import LoginBase from '@/views/auth/login_base';
import authWrapper from './auth-wrapper';

export default {
    components: {
        LoginBase,
        authWrapper,
    },
};
</script>
